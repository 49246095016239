import axios from 'axios'
import { Message } from 'element-ui'
// import store from '@/store'
// import { getAuthorization } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  // baseURL: '/apii', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 500000 // request timeout
})

const re = /^[A-Za-z0-9]+$/

// request interceptor
service.interceptors.request.use(
  config => {
    // 获取Token，你可以从localStorage或者Vuex的state中获取
    const token = localStorage.getItem('token'); // 示例从localStorage获取token

    if (token) {
      // 设置公共请求头
      config.headers['Authori-zation'] = token;
    }

    return config;
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    // console.log(res);
    // if (res.code === 401) {
    //   window.location.href = "/"
    // }
    if (res.code !== 20000 && res.code !== 'SUCCESS' && res.code !== 200) {
      if (document.getElementsByClassName('el-message').length === 0) {
        console.log('执行')
        Message({
          message: errorMessageTransfer(res.code, res.message),
          type: 'error',
          duration: 1.5 * 1000
        })
      }
      if (!response.config.url.includes('user/login') && !response.config.url.includes('/user/login/2fa')) {
        if (res.code === 50008 || res.code === 50012 || res.code === 50014 || res.code === 401 || res.code === 'UNAUTHORIZED') {
          // MessageBox.alert('Session expired. Please log in again.', 'Logout', {
          //   confirmButtonText: 'Log in again',
          //   type: 'warning'
          // }).then(() => {
          //   window.location.href = "/register"
          // })
          localStorage.removeItem('token')
          window.location.href = "/register"
        }
      }
      return Promise.reject(new Error(res.message || '报错'))
    } else {
      return res
    }
  },
  error => {
    if (error.response.status === 403) {
      if (error.response.data.indexOf('Enable JavaScript and cookies to continue') > -1) {
        console.log('执行')
        window.location.reload()
      } else {
        Message({
          message: '没有权限',
          type: 'error',
          duration: 1.5 * 1000
        })
      }
    }
    if (document.getElementsByClassName('el-message').length === 0) {
      Message({
        message: errorMessageTransfer(error.code, error.message),
        type: 'error',
        duration: 1.5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

function errorMessageTransfer(code, message) {
  if (message === 'Bad credentials') {
    return '密码错误'
  }
  if (re.test(message)) {
    if (code === 'UNAUTHORIZED') {
      return '授权失败'
    }
    if (code === 'FORBIDDEN') {
      return '请求被禁止'
    }
    if (code === 'ERROR') {
      return '报错，请联系技术人员'
    }
    if (code === 'BALANCE_INSUFFICIENT') {
      return '余额不足'
    }
    if (code === 'BANK_NO_SUPPORT') {
      return '银行不支持'
    }
    if (code === 'CARD_NO_ERROR') {
      return '卡号不正确'
    }
    if (code === 'AMOUNT_LIMIT') {
      return '额度限制'
    }
    if (code === 'AMOUNT_NOT_MATCCH') {
      return '金额不匹配'
    }
    if (code === 'ORDER_NO_FOUND') {
      return '订单不存在'
    }
    if (code === 'ORDER_REPEAT') {
      return '订单重复'
    }
    if (code === 'ORDER_LIMIT') {
      return '订单限制'
    }
    if (code === 'CARD_NO_LIMIT') {
      return '卡号限制'
    }
  }
  return message
}

export default service
