<template>

  <div class="body_wrap">
      <div class="backtotop">
        <a href="#" class="scroll">
          <i class="far fa-arrow-up"></i>
        </a>
      </div>
      <!-- <div id="preloader"></div> -->
      <header class="header_section">
        <div class="header_middle">
          <div class="container">
            <div class="row align-items-center">
              <div class="col col-lg-3 col-5">
                <div class="brand_logo">
                  <a class="brand_link" href="/">
                    <img src="./assets/logo.png">
                  </a>
                </div>
              </div>
              <div class="col col-lg-6 col-2">
                <nav class="main_menu navbar navbar-expand-lg">
                  <div class="main_menu_inner collapse navbar-collapse justify-content-center" id="main_menu_dropdown">
                    <ul class="main_menu_list ul_li">
                      <!-- <li class="active">
                        <a href="/">Home</a>
                      </li> -->
                      <!-- <li>
                        <a href="/register">register</a>
                      </li> -->
                      <!-- <li><a href="/register">Sign In</a></li>
                      <li><a style="cursor: pointer;" @click="tiaozhuanAccount()">Shopping Cart</a></li> -->
                      <li :class="stop === '1'?'active':''"><a href="/#1" @click="stop = '1'">HOT ITEMS</a></li>
                      <li :class="stop === '2'?'active':''"><a href="/#2" @click="stop = '2'">NEW ARRIVALS</a></li>
                      <li :class="stop === '3'?'active':''"><a href="/#3" @click="stop = '3'">BEST SELLER</a></li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div class="col col-lg-3 col-5">
                <ul class="header_icons_group ul_li_right">
                  <li>
                    <button class="mobile_menu_btn navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_menu_dropdown" aria-controls="main_menu_dropdown" aria-expanded="false" aria-label="Toggle navigation">
                      <i class="fal fa-bars"></i>
                    </button>
                  </li>
                  <li><a style="cursor: pointer;" @click="tiaozhuanAccount()"><i class="fa-thin fa-cart-shopping"></i></a></li>
                  <li><a style="cursor: pointer;" @click="tiaozhuanAccount2()"><i class="fa-regular fa-user"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="header_bottom">
          <div class="container">
            <div class="row align-items-center" style="padding:10px 0px;">
              <div class="col col-md-10 col-10">
                <div class="allcategories_dropdown">
                  <h2 class="title_text" style="color:#fff;"><i class="fa-duotone fa-fire"></i> Technology e-commerce, worry-free shopping!</h2>
                </div>
              </div>

              <!-- <div class="col col-md-2 col-2">
                <a @click="tiaozhuanAccount()">
                <button type="button" class="cart_btn">
                  <span class="cart_icon">
                    <i class="icon icon-ShoppingCart"></i>
                  </span>
                </button>
              </a>
              </div> -->
            </div>
          </div>
        </div>
      </header>
      <main>

        <!-- sidebar cart - start
        ================================================== -->
        <div class="sidebar-menu-wrapper">
          <div class="cart_sidebar">
            <button type="button" class="close_btn"><i class="fal fa-times"></i></button>

            <ul class="cart_items_list ul_li_block mb_30 clearfix">
              <li>
                <div class="item_image">
                  <img src="assets/images/cart/cart_img_1.jpg" alt="Getyootech - Gadgets Ecommerce Site Template">
                </div>
                <div class="item_content">
                  <h4 class="item_title">Yellow Blouse</h4>
                  <span class="item_price">MXN$30.00</span>
                </div>
                <button type="button" class="remove_btn"><i class="fal fa-trash-alt"></i></button>
              </li>
              <li>
                <div class="item_image">
                  <img src="assets/images/cart/cart_img_2.png" alt="Getyootech - Gadgets Ecommerce Site Template">
                </div>
                <div class="item_content">
                  <h4 class="item_title">Yellow Blouse</h4>
                  <span class="item_price">MXN$30.00</span>
                </div>
                <button type="button" class="remove_btn"><i class="fal fa-trash-alt"></i></button>
              </li>
              <li>
                <div class="item_image">
                  <img src="assets/images/cart/cart_img_3.png" alt="Getyootech - Gadgets Ecommerce Site Template">
                </div>
                <div class="item_content">
                  <h4 class="item_title">Yellow Blouse</h4>
                  <span class="item_price">MXN$30.00</span>
                </div>
                <button type="button" class="remove_btn"><i class="fal fa-trash-alt"></i></button>
              </li>
            </ul>

            <ul class="total_price ul_li_block mb_30 clearfix">
              <li>
                <span>Subtotal:</span>
                <span>MXN$90</span>
              </li>
              <li>
                <span>Vat 5%:</span>
                <span>MXN$4.5</span>
              </li>
              <li>
                <span>Discount 20%:</span>
                <span>- MXN$18.9</span>
              </li>
              <li>
                <span>Total:</span>
                <span>MXN$75.6</span>
              </li>
            </ul>

            <ul class="btns_group ul_li_block clearfix">
              <li><a class="btn btn_primary" href="/cart">View Cart</a></li>
              <li><a class="btn btn_secondary" href="checkout.html">Checkout</a></li>
            </ul>
          </div>

          <div class="cart_overlay"></div>
        </div>
        <!-- sidebar cart - end
        ================================================== -->

        <!-- product quick view modal - start
        ================================================== -->
        <div class="modal fade" id="quickview_popup" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalToggleLabel2">Product Quick View</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="product_details">
                  <div class="container">
                    <div class="row">
                      <div class="col col-lg-6">
                        <div class="product_details_image p-0">
                          <img :src="imgSrc" alt="Getyootech - Gadgets Ecommerce Site Template">
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="product_details_content">
                          <h2 class="item_title">CURREN 8109 Watches</h2>
                          <p>
                            It is a long established fact that a reader will be distracted eget velit. Donec ac tempus ante. Fusce ultricies massa massa. Fusce aliquam, purus eget sagittis vulputate
                          </p>
                          <div class="item_review">
                            <ul class="rating_star ul_li">
                              <li><i class="fa-solid fa-star"></i></li>
                              <li><i class="fa-solid fa-star"></i></li>
                              <li><i class="fa-solid fa-star"></i></li>
                              <li><i class="fa-solid fa-star"></i></li>
                              <li><i class="fa-solid fa-star"></i></li>
                            </ul>
                            <span class="review_value">3 Rating(s)</span>
                          </div>
                          <div class="item_price">
                            <span>MXN$620.00</span>
                            <del>MXN$720.00</del>
                          </div>

                          <hr>

                          <!-- <div class="item_attribute">
                            <h3 class="title_text">Options <span class="underline"></span></h3>
                            <form action="#">
                              <div class="row">
                                <div class="col col-md-6">
                                  <div class="select_option clearfix">
                                    <h4 class="input_title">Size *</h4>
                                    <select>
                                      <option data-display="- Please select -">Choose A Option</option>
                                      <option value="1">Some option</option>
                                      <option value="2">Another option</option>
                                      <option value="3" disabled>A disabled option</option>
                                      <option value="4">Potato</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col col-md-6">
                                  <div class="select_option clearfix">
                                    <h4 class="input_title">Color *</h4>
                                    <select>
                                      <option data-display="- Please select -">Choose A Option</option>
                                      <option value="1">Some option</option>
                                      <option value="2">Another option</option>
                                      <option value="3" disabled>A disabled option</option>
                                      <option value="4">Potato</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <span class="repuired_text">Repuired Fiields *</span>
                            </form>
                          </div>

                          <div class="quantity_wrap">
                            <form action="#">
                              <div class="quantity_input">
                                <button type="button" class="input_number_decrement">
                                  <i class="fal fa-minus"></i>
                                </button>
                                <input class="input_number" type="text" value="1">
                                <button type="button" class="input_number_increment">
                                  <i class="fal fa-plus"></i>
                                </button>
                              </div>
                            </form>

                            <div class="total_price">
                              Total: MXN$620,99
                            </div>
                          </div> -->

                          <ul class="default_btns_group ul_li">
                            <li><a class="addtocart_btn" href="/shop_details">Add To Cart</a></li>
                            
                          </ul>

                          <ul class="default_share_links ul_li">
                            <li>
                              <a class="facebook" href="#!">
                                <span><i class="fab fa-facebook-square"></i> Like</span>
                                <small>10K</small>
                              </a>
                            </li>
                            <li>
                              <a class="twitter" href="#!">
                                <span><i class="fab fa-twitter-square"></i> Tweet</span>
                                <small>15K</small>
                              </a>
                            </li>
                            <li>
                              <a class="google" href="#!">
                                <span><i class="fab fa-google-plus-square"></i> Google+</span>
                                <small>20K</small>
                              </a>
                            </li>
                            <li>
                              <a class="share" href="#!">
                                <span><i class="fas fa-plus-square"></i> Share</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- product quick view modal - end
        ================================================== -->

        <!-- slider_section - start
        ================================================== -->
        <section class="slider_section">
          <div class="main_slider" id="aaaa" data-slick='{"arrows": false}'>

            <div class="slider_item">
              <div class="container">
                <div class="row align-items-center justify-content-lg-between">
                  <div class="order-last col col-lg-6 col-md-6 col-sm-6">
                    <div class="slider_image" data-animation="fadeInRight" data-delay=".2s">
                      <img src="assets/images/slider/slider_image_4.png" alt="Getyootech - Gadgets Ecommerce Site Template">
                    </div>
                  </div>
                  <div class="col col-lg-5 col-md-6 col-sm-6">
                    <div class="slider_content">
                      <h3 class="small_title" data-animation="fadeInUp2" data-delay=".2s">Tech Products</h3>
                      <h4 class="big_title" data-animation="fadeInUp2" data-delay=".4s">VR all-in-one machine</h4>
                      <p data-animation="fadeInUp2" data-delay=".6s">Independent operations, inputs and outputs</p>
                      <div class="item_price" data-animation="fadeInUp2" data-delay=".6s">
                        <!-- <del>MXN$520.00</del> -->
                        <span class="sale_price">MXN$2460.00</span>
                      </div>
                      <a class="btn btn_primary" href="/shop_details?id=1&img=assets/images/slider/slider_image_4.png" data-animation="fadeInUp2" data-delay=".8s">Start Buying</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="slider_item">
              <div class="container">
                <div class="row align-items-center justify-content-lg-between">
                  <div class="order-last col col-lg-6 col-md-6 col-sm-6">
                    <div class="slider_image" data-animation="fadeInRight" data-delay=".2s">
                      <img src="assets/images/slider/slider_image_3.png" alt="Getyootech - Gadgets Ecommerce Site Template">
                    </div>
                  </div>
                  <div class="col col-lg-5 col-md-6 col-sm-6">
                    <div class="slider_content">
                      <h3 class="small_title" data-animation="fadeInUp2" data-delay=".2s">Tech Products</h3>
                      <h4 class="big_title" data-animation="fadeInUp2" data-delay=".4s">HyperX headphones</h4>
                      <p data-animation="fadeInUp2" data-delay=".6s">Breakthrough dual-chamber technology</p>
                      <div class="item_price" data-animation="fadeInUp2" data-delay=".6s">
                        <!-- <del>MXN$520.00</del> -->
                        <span class="sale_price">MXN$1660.00</span>
                      </div>
                      <a class="btn btn_primary" href="/shop_details?id=2&img=assets/images/slider/slider_image_3.png" data-animation="fadeInUp2" data-delay=".8s">Start Buying</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="slider_item">
              <div class="container">
                <div class="row align-items-center justify-content-lg-between">
                  <div class="order-last col col-lg-6 col-md-6 col-sm-6">
                    <div class="slider_image" data-animation="fadeInRight" data-delay=".2s">
                      <img src="assets/images/slider/slider_image_1.png" alt="Getyootech - Gadgets Ecommerce Site Template">
                    </div>
                  </div>
                  <div class="col col-lg-5 col-md-6 col-sm-6">
                    <div class="slider_content">
                      <h3 class="small_title" data-animation="fadeInUp2" data-delay=".2s">Tech Products</h3>
                      <h4 class="big_title" data-animation="fadeInUp2" data-delay=".4s">TP-Link Deco M4 Wi-Fi</h4>
                      <p data-animation="fadeInUp2" data-delay=".6s">Wireless system whole house coverage</p>
                      <div class="item_price" data-animation="fadeInUp2" data-delay=".6s">
                        <!-- <del>MXN$520.00</del> -->
                        <span class="sale_price">MXN$980.00</span>
                      </div>
                      <a class="btn btn_primary" href="/shop_details?id=3&img=assets/images/slider/slider_image_1.png" data-animation="fadeInUp2" data-delay=".8s">Start Buying</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- slider_section - end
        ================================================== -->

        <!-- policy_section - start
        ================================================== -->
        <section class="policy_section">
          <div class="policy_item">
            <div class="item_icon">
              <i class="icon icon-Truck"></i>
            </div>
            <div class="item_content">
              <h3 class="item_title">Free Shipping</h3>
              <p>
                Free shipping on all Mexico orders
              </p>
            </div>
          </div>

          <div class="policy_item">
            <div class="item_icon">
              <i class="icon icon-Headset"></i>
            </div>
            <div class="item_content">
              <h3 class="item_title">Support 24/7</h3>
              <p>
                Contact us 24 hours a day
              </p>
            </div>
          </div>

          <div class="policy_item">
            <div class="item_icon">
              <i class="icon icon-Wallet"></i>
            </div>
            <div class="item_content">
              <h3 class="item_title">100% Money Back</h3>
              <p>
                You have 30 days to Return
              </p>
            </div>
          </div>

          <div class="policy_item">
            <div class="item_icon">
              <i class="fa-light fa-rocket"></i>
            </div>
            <div class="item_content">
              <h3 class="item_title">30 Days Return</h3>
              <p>
                If goods have problems
              </p>
            </div>
          </div>

          <div class="policy_item">
            <div class="item_icon">
              <i class="icon icon-Dollars"></i>
            </div>
            <div class="item_content">
              <h3 class="item_title">Payment Secure</h3>
              <p>
                We ensure secure payment
              </p>
            </div>
          </div>
        </section>
        <!-- policy_section - end
        ================================================== -->

        <!-- category_section - start
        ================================================== -->
        <section class="category_section section_space" id="1">
          <div class="container">
            <div class="section_title mb-0">
              <h2 class="title_text"><i class="fa-duotone fa-fire"></i>HOT ITEMS</h2>
            </div>
            <div class="top_category_wrap arrows_topright">
              <div class="top_category_carousel" data-slick='{"dots": false}' v-if="shangpinData">

                <div class="slider_item" v-for="(item, index) in shangpinData.slice(11, 20)" :key="index">
                  <div class="category_boxed">
                    <a :href="'/shop_details?id='+item.id+'&img=assets/images/shop/product_img_5.png'">
                      <span class="item_image">
                        <img :src="'assets/images/' + item.image" alt="Getyootech - Gadgets Ecommerce Site Template">
                        <!-- <img src="assets/images/categories/category_1.png" alt="Getyootech - Gadgets Ecommerce Site Template"> -->
                      </span>
                      <span class="item_title" style="padding: 0px 8px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ item.storeName }}</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="carousel_nav">
                <button type="button" class="tc_left_arrow"><i class="fa-regular fa-angle-left"></i></button>
                <button type="button" class="tc_right_arrow"><i class="fa-regular fa-angle-right"></i></button>
              </div>
            </div>

          </div>
        </section>
        <!-- category_section - end
        ================================================== -->

        <!-- promotion_section - start
        ================================================== -->
        <section class="promotion_section">
          <div class="container">
            <div class="row promotion_banner_wrap">
              <div class="col col-lg-6">
                <div class="promotion_banner">
                  <div class="item_image">
                    <img src="assets/images/14/1.png" alt="Getyootech - Gadgets Ecommerce Site Template">
                  </div>
                  <div class="item_content" style="padding-right: 35px;">
                    <h3 class="item_title">notebook</h3>
                    <p>
                      Laptop Aspire 5 Core i5-1135G7, Quad Core, 2.40 GHz hasta 4.20 GHz, 8 GB DDR4, 512 GB SSD 
                    </p>
                    <a class="btn btn_primary" href="/shop_details?id=14">Shop Now</a>
                  </div>
                </div>
              </div>

              <div class="col col-lg-6">
                <div class="promotion_banner">
                  <div class="item_image">
                    <img src="assets/images/15/1.png" alt="Getyootech - Gadgets Ecommerce Site Template">
                  </div>
                  <div class="item_content" style="padding-right: 35px;">
                    <h3 class="item_title">ceramic cup</h3>
                    <p>
                      Le Bonne Table by Crown Baccara - Tazas de Cerámica, 2 piezas de 400 ml, Taza de Halloween 
                    </p>
                    <a class="btn btn_primary" href="/shop_details?id=15">Shop Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- promotion_section - end
        ================================================== -->

        <!-- new_arrivals_section - start
        ================================================== -->
        <section class="new_arrivals_section section_space" id="2">
          <div class="container">
            <div class="section_title mb-0">
              <h2 class="title_text"><i class="fa-duotone fa-sparkles"></i> New Arrivals</h2>
            </div>
            <div class="row newarrivals_products">
              <div class="col col-lg-5">
                <div class="deals_product_layout1">
                  <div class="bg_area">
                    <h3 class="item_title">Best Product Deals</h3>
                    <p>
                      Get a 20% Cashback when buying TWS Product From our Audio Technology.
                    </p>
                    <a class="btn border_primary">Shop Now</a>
                    <div class="item_image">
                      <img src="assets/images/deals/deals_product_1.png" alt="Getyootech - Gadgets Ecommerce Site Template">
                    </div>
                  </div>
                </div>
              </div>

              <div class="col col-lg-7">
                <div class="new_arrivals_carousel arrows_topright">
                  <div class="common_carousel_2" data-slick='{"dots": false}'>
                    <div class="slider_item" v-for="(item, index) in shangpinData2" :key="index">
                      <div class="product_layout1" v-for="(item2, index2) in item" :key="index2">
                        <div class="item_image" @click="$router.push('/shop_details?id='+item2.id)">
                          <img :src="'assets/images/' + item2.image" alt="Getyootech - Gadgets Ecommerce Site Template">
                          <img :src="'assets/images/' + item2.image" alt="Getyootech - Gadgets Ecommerce Site Template">
                          <!-- <a class="quickview_btn" data-bs-toggle="modal" @click="imgSrc = 'assets/images/shop/product_img_1.png'" href="#quickview_popup" role="button">Quick View</a> -->
                        </div>
                        <div class="item_content">
                          <h3 class="item_title">
                            <a :href="'/shop_details?id='+item2.id" style="width: 100%;padding: 0px 8px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                              {{ item2.storeName }}
                            </a>
                          </h3>
                          <ul class="rating_star ul_li">
                            <li><i class="fa-solid fa-star"></i></li>
                            <li><i class="fa-solid fa-star"></i></li>
                            <li><i class="fa-solid fa-star"></i></li>
                            <li><i class="fa-solid fa-star"></i></li>
                            <li><i class="fa-solid fa-star"></i></li>
                          </ul>
                          <div class="item_price">
                            <span>MXN${{ item2.price }}</span>
                          </div>
                          <ul class="item_btns_group ul_li">
                            <li><a class="addtocart_btn" :href="'/shop_details?id='+item2.id">Add To Cart</a></li>
                            
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="carousel_nav">
                    <button type="button" class="cc2_left_arrow"><i class="fa-regular fa-angle-left"></i></button>
                    <button type="button" class="cc2_right_arrow"><i class="fa-regular fa-angle-right"></i></button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
        <!-- new_arrivals_section - end
        ================================================== -->

        <!-- product_section - start
        ================================================== -->
        <section class="product_section" id="3">
          <div class="container">
            <ul class="tabs_nav nav" role="tablist">
              <li role="presentation">
                <button class="active" data-bs-toggle="tab" data-bs-target="#bestseller_tab" type="button" role="tab" aria-selected="true">Best Seller</button>
              </li>
            </ul>

            <div class="tab-content tad_has_carousel">
              <div class="tab-pane fade show active" id="bestseller_tab" role="tabpanel">
                <div class="product_carousel_wrap arrows_topright">
                  <div class="best_seller_carousel" data-slick='{"dots": false}'>
                    <div class="product_layout1" v-for="(item, index) in shangpinData.slice(3, 8)" :key="index">
                      <div class="item_image" @click="$router.push('/shop_details?id='+item.id)">
                        <img :src="'assets/images/' + item.image" alt="Getyootech - Gadgets Ecommerce Site Template">
                        <img :src="'assets/images/' + item.image" alt="Getyootech - Gadgets Ecommerce Site Template">
                      </div>
                      <div class="item_content">
                        <h3 class="item_title">
                          <a :href="'/shop_details?id='+item.id" style="width: 100%;padding: 0px 8px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                            {{ item.storeName }}
                          </a>
                        </h3>
                        <ul class="rating_star ul_li">
                          <li><i class="fa-solid fa-star"></i></li>
                          <li><i class="fa-solid fa-star"></i></li>
                          <li><i class="fa-solid fa-star"></i></li>
                          <li><i class="fa-solid fa-star"></i></li>
                          <li><i class="fa-solid fa-star"></i></li>
                        </ul>
                        <div class="item_price">
                          <span>MXN${{ item.price }}</span>
                        </div>
                        <ul class="item_btns_group ul_li">
                          <li><a class="addtocart_btn" :href="'/shop_details?id='+item.id">Add To Cart</a></li>
                          
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="carousel_nav">
                    <button type="button" class="bsc_left_arrow"><i class="fa-regular fa-angle-left"></i></button>
                    <button type="button" class="bsc_right_arrow"><i class="fa-regular fa-angle-right"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- newsletter_section - start
        ================================================== -->
        <section class="newsletter_section">
          <div class="container">
            <div class="row align-items-center">
              <div class="col col-lg-6">
                <h2 class="newsletter_title text-white">
                  <i class="icofont-paper-plane"></i>
                  Subscribe our Newsletter
                </h2>
              </div>
              <div class="col col-lg-6">
                <form action="#!">
                  <div class="newsletter_form">
                    <input type="email" name="email" placeholder="Enter your email address">
                    <button type="submit" class="btn btn_danger">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <!-- newsletter_section - end
        ================================================== -->

      </main>
      <footer class="footer_section">
        <div class="footer_widget_area">
          <div class="container">
            <div class="row">
              <div class="col col-lg-2 col-md-3 col-sm-6">
                <div class="footer_widget footer_useful_links">
                  <h3 class="footer_widget_title text-uppercase">Quick Links</h3>
                  <ul class="ul_li_block">
                    <li><a href="/register">Sign In</a></li>
                    <!-- <li><a href="/register">Sign Up</a></li> -->
                  </ul>
                </div>
              </div>

              <div class="col col-lg-2 col-md-3 col-sm-6">
                <div class="footer_widget footer_useful_links">
                  <h3 class="footer_widget_title text-uppercase">Custom area</h3>
                  <ul class="ul_li_block">
                    <li><a href="/account">My Account</a></li>
                    <li><a href="/cart">My Cart</a></li>
                  </ul>
                </div>
              </div>

              <div class="col col-lg-8 col-md-6 col-sm-12">
                <div class="footer_widget footer_useful_links">
                  <h3 class="footer_widget_title text-uppercase">contact us</h3>
                  <ul class="ul_li_block">
                    <li>Company Name： INFORMATICA GH SOLUCIONES, S.A. DE C.V.</li>
                    <li>E-mail: hungrybulo@outlook.com</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
</template>
<script>
import {
  apiFrontIndexProduct, apiFrontProductList
} from '@/api/goods'
export default {
  name: 'Home',
  data() {
    return {
      options: [],
      stop: '1',
      amount: 0,
      imgSrc: 'assets/images/details/product_details_img_1.png',
      shangpinList: [],
      shangpinData: [
        {
          id: 20,
          image: "20/1.png",
          storeName: "glade - Mini Gel Car, Aromatizante para Auto, Aroma Acqua, 70g",
          price: "15.00"
        },
        {
          id: 19,
          image: "19/1.png",
          storeName: "Fabuloso, Frescura Activa, Limpiador Líquido, Fresca Lavanda, Protección antiviral y antibacterial",
          price: "76.50"
        },
        {
          id: 18,
          image: "18/1.png",
          storeName: "AMD RYZEN 5 5600X - Procesador, 3.7GHz, 6 Núcleos, Socket AM4",
          price: "2588.00"
        },
        {
          id: 17,
          image: "17/1.png",
          storeName: "WAYAMY Cuerda para Saltar, Fácil de Ajustar y con Manijas Cómodas,Calidad Premium,",
          price: "99.00"
        },
        {
          id: 16,
          image: "16/1.png",
          storeName: "MEGA BLOKS, Bloques de Construcción de Plástico, Gran Bolsa Rosa para Construir, Juguete para Niños",
          price: "199.00"
        },
        {
          id: 15,
          image: "15/1.png",
          storeName: "Le Bonne Table by Crown Baccara - Tazas de Cerámica, 2 piezas de 400 ml, Taza de Halloween",
          price: "391.54"
        },
        {
          id: 14,
          image: "14/1.png",
          storeName: "Laptop Aspire 5 Core i5-1135G7, Quad Core, 2.40 GHz hasta 4.20 GHz, 8 GB DDR4, 512 GB SSD",
          price: "10999.00"
        },
        {
          id: 13,
          image: "13/1.png",
          storeName: "Nintendo Switch Modelo OLED w/ Neon Red & Neon Blue Joy-Con - Standard Edition (Version Internacional)",
          price: "5249.00"
        },
        {
          id: 12,
          image: "12/1.png",
          storeName: "Fuel For Fans Gorra Red Bull Racing F1 2022 Sergio Checo Pérez Azul Marino",
          price: "799.00"
        },
        {
          id: 11,
          image: "11/1.png",
          storeName: "MAS Color - Colores Intensos 6.64L Detergente Líquido para Cuidar el Color de la Ropa (88 cargas)",
          price: "207.00"
        },
        {
          id: 10,
          image: "10/1.png",
          storeName: "Logitech G203 LIGHTSYNC Mouse Gaming con Iluminación RGB Personalizable",
          price: "599.00"
        },
        {
          id: 9,
          image: "9/1.png",
          storeName: "LG 24GQ50F-B Ultragear Gaming Monitor 24\" VA FHD 165Hz 1ms MBR AMD FreeSync HDMI 1.4 X 2, DP 1.2 X 1",
          price: "2999.00"
        },
        // {
        //   id: 7,
        //   image: "7/1.png",
        //   storeName: "车载小鹿首饰架摆件鹿角树形耳钉首饰展示架耳环架手链饰品收纳首饰挂件 白色",
        //   price: "13.00"
        // },
        {
          id: 6,
          image: "6/1.png",
          storeName: "Samsung Galaxy Note IV",
          price: "1792.00"
        },
        {
          id: 5,
          image: "5/1.png",
          storeName: "Anker SoundCore Life Q20",
          price: "2888.00"
        },
        {
          id: 4,
          image: "4/1.png",
          storeName: "CURREN 8109 Watches",
          price: "3999.00"
        },
        {
          id: 3,
          image: "3/1.png",
          storeName: "TP-Link Deco M4 Wi-Fi",
          price: "980.00"
        },
        {
          id: 2,
          image: "2/1.png",
          storeName: "HECATE G33BT",
          price: "1660.00"
        },
        {
          id: 1,
          image: "1/1.png",
          storeName: "pico 4",
          price: "2560.00"
        }
      ],
      shangpinData2: [
        [
          {
            id: 20,
            image: "20/1.png",
            storeName: "glade - Mini Gel Car, Aromatizante para Auto, Aroma Acqua, 70g",
            price: "15.00"
          },
          {
            id: 19,
            image: "19/1.png",
            storeName: "Fabuloso, Frescura Activa, Limpiador Líquido, Fresca Lavanda, Protección antiviral y antibacterial",
            price: "76.50"
          }
        ],
        [
          {
            id: 18,
            image: "18/1.png",
            storeName: "AMD RYZEN 5 5600X - Procesador, 3.7GHz, 6 Núcleos, Socket AM4",
            price: "2588.00"
          },
          {
            id: 17,
            image: "17/1.png",
            storeName: "WAYAMY Cuerda para Saltar, Fácil de Ajustar y con Manijas Cómodas,Calidad Premium,",
            price: "99.00"
          }
        ],
        [
          {
            id: 16,
            image: "16/1.png",
            storeName: "MEGA BLOKS, Bloques de Construcción de Plástico, Gran Bolsa Rosa para Construir, Juguete para Niños",
            price: "199.00"
          },
          {
            id: 15,
            image: "15/1.png",
            storeName: "Le Bonne Table by Crown Baccara - Tazas de Cerámica, 2 piezas de 400 ml, Taza de Halloween",
            price: "391.54"
          }
        ],
        [
          {
            id: 14,
            image: "14/1.png",
            storeName: "Laptop Aspire 5 Core i5-1135G7, Quad Core, 2.40 GHz hasta 4.20 GHz, 8 GB DDR4, 512 GB SSD",
            price: "10999.00"
          },
          {
            id: 13,
            image: "13/1.png",
            storeName: "Nintendo Switch Modelo OLED w/ Neon Red & Neon Blue Joy-Con - Standard Edition (Version Internacional)",
            price: "5249.00"
          }
        ],
        [
          {
            id: 12,
            image: "12/1.png",
            storeName: "Fuel For Fans Gorra Red Bull Racing F1 2022 Sergio Checo Pérez Azul Marino",
            price: "799.00"
          },
          {
            id: 11,
            image: "11/1.png",
            storeName: "MAS Color - Colores Intensos 6.64L Detergente Líquido para Cuidar el Color de la Ropa (88 cargas)",
            price: "207.00"
          }
        ],
        [
          {
            id: 10,
            image: "10/1.png",
            storeName: "Logitech G203 LIGHTSYNC Mouse Gaming con Iluminación RGB Personalizable",
            price: "599.00"
          },
          {
            id: 9,
            image: "9/1.png",
            storeName: "LG 24GQ50F-B Ultragear Gaming Monitor 24\" VA FHD 165Hz 1ms MBR AMD FreeSync HDMI 1.4 X 2, DP 1.2 X 1",
            price: "2999.00"
          }
        ]
      ]
    }
  },
  created() {
    apiFrontIndexProduct(2).then(res => {
      console.log(res)
    })
    var data = {
      limit: 100,
      page: 1
    }
    apiFrontProductList(data).then(res => {
      console.log(res)
      this.shangpinList = res.data.list
        setTimeout(() => {
          const script = document.createElement('script');
          script.src = 'assets/js/main.js';
          document.body.appendChild(script);
        }, 1000);
    })
  },
  methods: {
    tiaozhuanAccount() {
      if (localStorage.getItem('token')) {
        this.$router.push('/cart')
      } else {
        this.$router.push('/register')
        const h = this.$createElement;
        this.$notify({
          title: 'please log in',
          message: h('i', { style: 'color: red'}, 'You have not logged into your account')
        });
      }
    },
    tiaozhuanAccount2() {
      if (localStorage.getItem('token')) {
        this.$router.push('/account')
      } else {
        this.$router.push('/register')
        const h = this.$createElement;
        this.$notify({
          title: 'please log in',
          message: h('i', { style: 'color: red'}, 'You have not logged into your account')
        });
      }
    }
  }
}
</script>
