import request from '@/utils/request'
import url from '@/utils/url'

export function apiFrontIndexProduct(type) {
  return request({
    url: url.aldUrl + '/api/front/index/product/'+type,
    method: 'get'
  })
}

export function apiFrontRegisterEmail(data) {
  return request({
    url: url.aldUrl + '/api/front/register/email',
    method: 'post',
    data
  })
}

export function apiFrontCartCount(params) {
  return request({
    url: url.aldUrl + '/api/front/cart/count',
    method: 'get',
    params
  })
}

export function apiFrontLoginEmail(data) {
  return request({
    url: url.aldUrl + '/api/front/login/email',
    method: 'post',
    data
  })
}

export function apiFrontUserBalance() {
  return request({
    url: url.aldUrl + '/api/front/user/balance',
    method: 'get'
  })
}

export function apiFrontUser() {
  return request({
    url: url.aldUrl + '/api/front/user',
    method: 'get'
  })
}

export function apiFrontExtractClabeCash(data) {
  return request({
    url: url.aldUrl + '/api/front/extract/clabe/cash',
    method: 'post',
    data
  })
}

export function apiFrontRechargeClabe(data) {
  return request({
    url: url.aldUrl + '/api/front/recharge/clabe',
    method: 'post',
    data
  })
}

export function apiFrontUserEdit(data) {
  return request({
    url: url.aldUrl + '/api/front/user/edit',
    method: 'post',
    data
  })
}

export function apiFrontProductDetail(id) {
  return request({
    url: url.aldUrl + '/api/front/product/detail/' + id,
    method: 'get'
  })
}

export function apiFrontCartList(params) {
  return request({
    url: url.aldUrl + '/api/front/cart/list',
    method: 'get',
    params
  })
}

export function apiFrontCartSave(data) {
  return request({
    url: url.aldUrl + '/api/front/cart/save',
    method: 'post',
    data
  })
}

export function apiFrontProducts() {
  return request({
    url: url.aldUrl + '/api/front/products',
    method: 'get'
  })
}

export function apiFrontCartResetcart(data) {
  return request({
    url: url.aldUrl + '/api/front/cart/resetcart',
    method: 'post',
    data
  })
}

export function apiFrontCartDelete(id) {
  return request({
    url: url.aldUrl + '/api/front/cart/delete?ids='+id,
    method: 'post'
  })
}

export function apiFrontOrderPreOrder(data) {
  return request({
    url: url.aldUrl + '/api/front/order/pre/order',
    method: 'post',
    data
  })
}

export function apiFrontOrderComputedPrice(data) {
  return request({
    url: url.aldUrl + '/api/front/order/computed/price',
    method: 'post',
    data
  })
}

export function apiFrontOrderLoadPre(id) {
  return request({
    url: url.aldUrl + '/api/front/order/load/pre/'+id,
    method: 'get'
  })
}

export function apiFrontOrderCreate(data) {
  return request({
    url: url.aldUrl + '/api/front/order/create',
    method: 'post',
    data
  })
}

export function apiFrontPayPayment(data) {
  return request({
    url: url.aldUrl + '/api/front/pay/payment',
    method: 'post',
    data
  })
}

export function apiFrontOrderDetail(id) {
  return request({
    url: url.aldUrl + '/api/front/order/detail/'+id,
    method: 'get'
  })
}

export function apiFrontPayQueryExtractDisburseResult() {
  return request({
    url: url.aldUrl + '/api/front/pay/queryExtractDisburseResult',
    method: 'get'
  })
}

export function apiFrontOrderList(params) {
  return request({
    url: url.aldUrl + '/api/front/order/list',
    method: 'get',
    params
  })
}

export function apiFrontExtractClabeRecord(params) {
  return request({
    url: url.aldUrl + '/api/front/extract/clabe/record',
    method: 'get',
    params
  })
}

export function apiFrontProductList(params) {
  return request({
    url: url.aldUrl + '/api/front/product/list',
    method: 'get',
    params
  })
}
